import React, { useEffect } from 'react'
import { Flex, Box, Text, Image } from 'rebass'
import { useDispatch, useSelector } from 'react-redux'
import { formatUnits, parseUnits } from 'ethers'
import { history } from './redux'
import { fmtDate, shorten, copyToClipboard } from './util'

export default function History() {
  const dispatch = useDispatch()
  let { utxosh = [] } = useSelector(state => state)
  useEffect(() => {
    dispatch(history())
    return async () => {}
  }, [])
  console.log('utxosh summary', utxosh)
  return (
    <Box
      sx={{
        maxWidth: '100%',
        border: '0.1875em solid #000',
        boxShadow: '0 0.625em',
        padding: '0.625em',
        fontSize: '14px'
      }}
    >
      <Flex
        sx={{
          fontSize: [24, 26, 28],
          fontWeight: 'bold',
          marginTop: 0,
          // marginBottom: '0.625em',
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        <span> History</span>
        <Box
          sx={{
            cursor: 'pointer',
            fontWeight: 'normal',
            fontSize: [16, 18],
            paddingTop: '0.3125em'
          }}
          onClick={() => dispatch(history())}
        >
          {' '}
          {'↻'}
        </Box>
      </Flex>

      {
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '9em 6em 8.5625em 9em 9em 12.5%',
            marginBottom: '0.625em'
          }}
        >
          <span> </span>
          <span> </span>
          <span>
            {' '}
            <b>Amount</b>{' '}
          </span>
          <span>
            {' '}
            <b>From</b>{' '}
          </span>
          <span>
            {' '}
            <b>To</b>{' '}
          </span>
          <span>
            {' '}
            <b>Note</b>{' '}
          </span>
        </Box>
      }
      {utxosh.map(u => {
        return (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '9em 6em 8.5625em 9em 9em 12.5%',
              marginBottom: '0.625em',
              padding: '0.3125em',
              border: '0.0625em solid #000',
              boxShadow: '0 0.125em'
            }}
            key={JSON.stringify(u)}
          >
            <span>
              {' '}
              {u.date
                .toLocaleString('en-US', {
                  dateStyle: 'short',
                  timeStyle: 'short'
                })
                .replace(',', '')}{' '}
            </span>

            <span>
              <a
                target="_blank"
                href={`https://gnosisscan.io/tx/${u.tx}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {u.type.endsWith('transfer')
                  ? 'Transfer'
                  : u.type[0].toUpperCase() + u.type.slice(1)}
              </a>
            </span>
            <span>
              <Image
                src={'/img/hog-logo.png'}
                sx={{
                  width: ['1.4em'],
                  borderRadius: 10,
                  verticalAlign: 'top',
                  margin: '0 0.3125em 0 0'
                }}
              ></Image>
              {u.type === 'outbound transfer' || u.type === 'withdraw'
                ? '-'
                : '+'}
              {u.amount}HoG
            </span>
            <span
              onClick={copyToClipboard.bind(null, u.from)}
              style={{ cursor: 'pointer' }}
            >
              {' '}
              {shorten(u.from)}{' '}
            </span>
            <span
              onClick={copyToClipboard.bind(null, u.to)}
              style={{ cursor: 'pointer' }}
            >
              {' '}
              {shorten(u.to)}{' '}
            </span>
            <span> {u.note} </span>
          </Box>
        )
      })}
    </Box>
  )
}
