const { Contract, JsonRpcProvider, getDefaultProvider } = require('ethers')
const Utxo = require('./utxo')
const KeyPair = require('./keypair')
const {
  ERC20_ABI,
  POOL_ABI,
  REGISTRY_ABI,
  resolveShieldedAddress,
  resolveNativeAddress,
  bigNumToHex,
  bigNumToBuf,
  mapTransactArgs,
  poseidonHash,
  poseidonHash2,
  sumAmounts,
  onprogress,
  progress
} = require('./utils')
const poolAbi = require('./pool.abi.json')
const registryAbi = require('./registry.abi.json')
const chain = require('./chain')

let omnipool
let registry
let core
let config
let findUtxos
let findUtxosH

module.exports = init

function init(opts, _opts) {
  if (/^chiado$/i.test(opts)) {
    config = { ...chain.chiado, ..._opts }
  } else if (/^gnosis$/i.test(opts)) {
    config = { ...chain.gnosis, ..._opts }
  } else {
    config = {
      provider: getDefaultProvider(),
      zkAssetsBaseUrl: 'artifacts/circuits',
      merkleTreeHeight: 5,
      ...opts
    }
  }

  omnipool = require('./omnipool')(config)
  registry = require('./registry')(config)
  core = require('./core')(config)
  findUtxos = require('./find-utxos')(config)
  findUtxosH = require('./find-utxosh')(config)
  const gelato = require('./gelato')(config)

  if (!config.provider) {
    config.provider = getDefaultProvider()
  }

  if (typeof config.provider === 'string') {
    config.provider = new JsonRpcProvider(config.provider)
  }
  if (typeof config.pool === 'string') {
    config.pool = new Contract(config.pool, poolAbi, {
      provider: config.provider
    })
  }
  if (typeof config.registry === 'string') {
    config.registry = new Contract(config.registry, registryAbi, {
      provider: config.provider
    })
  }

  return {
    omnipool,
    registry,
    core,
    utils: {
      ERC20_ABI,
      POOL_ABI,
      REGISTRY_ABI,
      findUtxos,
      findUtxosH,
      sumAmounts,
      mapTransactArgs,
      resolveShieldedAddress,
      resolveNativeAddress,
      poseidonHash,
      poseidonHash2,
      bigNumToHex,
      bigNumToBuf,
      gelatoRelayFeeEstimate: gelato.relayFeeEstimate,
      onprogress,
      progress
    },
    config,
    KeyPair,
    Utxo,
    Buffer
  }
}
