module.exports = {
  chiado: {
    chainId: 10200,
    provider: 'https://rpc.chiado.gnosis.gateway.fm',
    registry: '0x921444b951c6a208a111bd77E88f8362cfa50172',
    pool: '0x8e2bbA34C12C9272Bc322d3322f579a4132465D3',
    zkAssetsBaseUrl:
      'https://bafybeif6kflwyofxmer4rt7x47kfv3k3zbzega5tctvkq7yubd2c7fp7q4.ipfs.nftstorage.link/ipfs/bafybeif6kflwyofxmer4rt7x47kfv3k3zbzega5tctvkq7yubd2c7fp7q4',
    merkleTreeHeight: 23
  },
  gnosis: {
    chainId: 100,
    provider:
      // https://rpc.circlesubi.id MAYBE?
      'https://rpc.eu-central-2.gateway.fm/v4/gnosis/archival/mainnet?apiKey=RBQ1ygy4IhH0K00AEViZOYtQIzEKAHPN.wyPL3JGGn5GJGbnv',
    registry: '0x8aB41cbE60eF21f117740d22ba540b80cB12eA5E',
    pool: '0xb898EBD15f88b247a7Fc3868DCf4B8D7db030CC1',
    zkAssetsBaseUrl:
      'https://bafybeihfg25nm6wuoea4sj443pwprhdjiatclamsdtb3oobepxifrfcclu.ipfs.nftstorage.link/ipfs/bafybeihfg25nm6wuoea4sj443pwprhdjiatclamsdtb3oobepxifrfcclu',
    merkleTreeHeight: 23
  }
}
