const { randomBytes } = require('crypto')
const { BigNumber } = require('@ethersproject/bignumber')
const { AbiCoder, keccak256 } = require('ethers')
const { poseidon } = require('circomlib')
const ERC20_ABI = require('./erc20.abi.json')
const POOL_ABI = require('./pool.abi.json')
const REGISTRY_ABI = require('./registry.abi.json')

const NATIVE_ADDRESS_PATTERN = /^0x[a-fA-F0-9]{40}$/
const SHIELDED_ADDRESS_PATTERN = /^0x[a-fA-F0-9]{128}$/
const FIELD_SIZE = BigNumber.from(
  '21888242871839275222246405745257275088548364400416034343698204186575808495617'
)

module.exports = {
  ERC20_ABI,
  POOL_ABI,
  REGISTRY_ABI,
  FIELD_SIZE,
  NATIVE_ADDRESS_PATTERN,
  SHIELDED_ADDRESS_PATTERN,
  poseidonHash,
  poseidonHash2,
  mapTransactArgs,
  randomBigNum,
  getExtDataHash,
  bigNumToHex,
  bigNumToBuf,
  shuffle,
  sortDescByAmount,
  sumAmounts,
  onprogress,
  progress
}

const progressHandlers = []
function onprogress(handler) {
  progressHandlers.push(handler)
  return function offprogress() {
    progressHandlers.splice(progressHandlers.indexOf(handler), 1)
  }
}

function progress(desc) {
  progressHandlers.forEach(handler => handler(desc))
}

function poseidonHash(items) {
  return BigNumber.from(poseidon(items).toString())
}

function poseidonHash2(a, b) {
  return poseidonHash([a, b])
}

/** Generate random number of specified byte length */
function randomBigNum(nbytes = 31) {
  return BigNumber.from(randomBytes(nbytes))
}

function getExtDataHash({
  recipient,
  extAmount,
  relayer,
  fee,
  encryptedOutput1,
  encryptedOutput2,
  unwrap,
  token
}) {
  const abi = new AbiCoder()

  const encodedData = abi.encode(
    [
      'tuple(address recipient,int256 extAmount,address relayer,uint256 fee,bytes encryptedOutput1,bytes encryptedOutput2,bool unwrap,address token)'
    ],
    [
      [
        bigNumToHex(recipient, 20),
        bigNumToHex(extAmount),
        bigNumToHex(relayer, 20),
        bigNumToHex(fee),
        encryptedOutput1,
        encryptedOutput2,
        unwrap,
        token
      ]
    ]
  )
  const hash = keccak256(encodedData)
  return BigNumber.from(hash).mod(FIELD_SIZE)
}

/** BigNumber to hex string of specified length */
function bigNumToHex(number, length = 32) {
  let result =
    '0x' +
    (number instanceof Buffer
      ? number.toString('hex')
      : BigNumber.from(number).toHexString().replace('0x', '')
    ).padStart(length * 2, '0')
  if (result.indexOf('-') > -1) {
    result = '-' + result.replace('-', '')
  }
  return result
}

/** Convert value into buffer of specified byte length */
function bigNumToBuf(value, length) {
  return Buffer.from(
    BigNumber.from(value)
      .toHexString()
      .slice(2)
      .padStart(length * 2, '0'),
    'hex'
  )
}

function shuffle(array) {
  let currentIndex = array.length
  let randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element... FIXME use a csprng
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ]
  }

  return array
}

function mapTransactArgs([_args, _extData]) {
  const args = [
    _args.proof,
    _args.root,
    _args.inputNullifiers,
    _args.outputCommitments,
    _args.publicAmount,
    _args.extDataHash
  ]
  const extData = [
    _extData.recipient,
    _extData.extAmount,
    _extData.relayer,
    _extData.fee,
    _extData.encryptedOutput1,
    _extData.encryptedOutput2,
    _extData.unwrap,
    _extData.token
  ]
  return [args, extData]
}

/**
 * Sorts utxos in desc order by amount.
 *
 * @param {Utxo[]} utxos
 * @returns {Utxo[]} utxos
 */
function sortDescByAmount(utxos = []) {
  return utxos.sort((a, b) => Number(b.amount.sub(a.amount)))
}

/**
 * Sums all utxos amounts.
 *
 * @param {Utxo[]} utxos
 * @returns {BigNumber} total amount
 */
function sumAmounts(utxos = []) {
  return utxos.reduce((acc, cur) => acc.add(cur.amount), BigNumber.from(0))
}
