import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadBalance } from './redux'

export default function RefreshBalanceButton({ style }) {
  const { shieldedAddress } = useSelector(state => state)
  const dispatch = useDispatch()

  return (
    <span
      aria-label="Refresh Balance"
      style={{
        width: 'auto',
        cursor: 'pointer',
        ...style
      }}
      onClick={_ => (shieldedAddress ? dispatch(loadBalance()) : null)}
    >
      {'↻'}
    </span>
  )
}
