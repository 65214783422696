const { parseEther } = require('ethers')
const { BigNumber } = require('@ethersproject/bignumber')
const Utxo = require('./utxo')
const KeyPair = require('./keypair')
const { sortDescByAmount, sumAmounts, progress } = require('./utils')

module.exports = config => {
  const core = require('./core')(config)
  const findUtxos = require('./find-utxos')(config)
  const gelato = require('./gelato')(config)

  /**
   * Deposits given amount to shielded recipient.
   * The deposit value is public on the blockchain.
   *
   * @param {Signer} signer depositor
   * @param {number|string} amount deposit amount
   * @param {string} token ERC20 token address
   * @param {string} recipient shielded address
   * @param {undefined|string} note tx note
   * @returns {{ receipt, keypair }} tx receipt and maybe a derived shielded account
   */
  async function fund({ signer, amount, token, recipient, note }) {
    progress('Initiating funding')
    const depositUtxo = new Utxo({
      keypair: KeyPair.fromString(recipient),
      amount: BigNumber.from(parseEther(String(amount))),
      token,
      note,
      type: 1
    })
    const receipt = await core.transact({
      pool: config.pool.connect(signer),
      outputs: [depositUtxo],
      token
    })
    progress('')
    return { receipt }
  }

  /**
   * Transfers given amount within the pool.
   *
   * @param {Signer} signer sender
   * @param {Contract} pool Omnipool
   * @param {KeyPair} keypair sender's shielded account keypair
   * @param {string[]} peers Peer shielded addresses
   * @param {number|string} amount transfer amount
   * @param {string} token ERC20 token address
   * @param {string} recipient shielded address
   * @param {boolean} useGelatoRelay use gelato relay?
   * @param {undefined|string} note tx note
   * @returns {receipt} tx receipt
   */
  async function transfer({
    signer,
    keypair,
    amount: _amount,
    token,
    recipient,
    note,
    peers = config.peers,
    pool = config.pool,
    useGelatoRelay
  }) {
    if (keypair.address() === recipient) {
      throw Error('cannot transfer to self')
    }
    progress('Initiating transfer')
    const amount = BigNumber.from(parseEther(String(_amount)))
    token = token.toLowerCase()
    const utxos = await findUtxos({
      pool,
      keypair,
      peers,
      tokens: [token]
    }).then(utxos => sortDescByAmount(utxos[token]).slice(0, 16))
    const spend = sumAmounts(utxos)
    const plusUtxo = new Utxo({
      amount,
      token,
      keypair: KeyPair.fromString(recipient),
      note,
      type: 2
    })
    const minusUtxo = new Utxo({
      amount: spend.sub(amount),
      token,
      keypair,
      encryptEphemeral: true,
      type: 2
    })

    if (useGelatoRelay) {
      const minusUtxo = new Utxo({
        amount: spend.sub(amount),
        token,
        keypair,
        encryptEphemeral: true,
        type: 2
      })
      progress('')

      //TODO GELATO SPONSORED
      return gelato.relaySponsored({
        pool: config.pool.connect(signer),
        inputs: utxos,
        outputs: [plusUtxo, minusUtxo],
        token
      })
    } else {
      const receipt = await core.transact({
        pool: config.pool.connect(signer),
        inputs: utxos,
        outputs: [plusUtxo, minusUtxo],
        token
      })
      progress('')
      return { receipt }
    }
  }

  /**
   * Withdraws given amount from the pool.
   *
   * @param {Signer} signer sender
   * @param {KeyPair} keypair withdrawer's shielded account keypair
   * @param {Contract} pool Omnipool
   * @param {string[]} peers Peer shielded addresses
   * @param {number|string} amount withdraw amount
   * @param {string} token ERC20 token address
   * @param {string} recipient gnosis address
   * @param {boolean} useGelatoRelay use gelato relay?
   * @param {boolean} unwrap Unwrap WXDAI?
   * @returns {receipt} tx receipt
   */
  async function withdraw({
    signer,
    keypair,
    amount,
    token,
    recipient,
    unwrap,
    peers = config.peers,
    pool = config.pool,
    useGelatoRelay,
    gelatoFee
  }) {
    progress('Initiating withdrawal')
    const utxos = await findUtxos({
      pool,
      keypair,
      peers,
      tokens: [token]
    }).then(utxos => sortDescByAmount(utxos[token]).slice(0, 16))
    const spend = sumAmounts(utxos)
    const minusUtxo = new Utxo({
      //TODO adjust 4 stablecoin 6 decimals by reading $token.decimals() off blokchain
      amount: spend.sub(BigNumber.from(parseEther(String(amount)))),
      token,
      keypair,
      encryptEphemeral: true,
      type: 3
    })
    if (useGelatoRelay) {
      return gelato.relaySponsored({
        pool: config.pool.connect(signer),
        inputs: utxos,
        outputs: [minusUtxo],
        token,
        recipient,
        unwrap
      })
    } else {
      const receipt = await core.transact({
        pool: config.pool.connect(signer),
        inputs: utxos,
        outputs: [minusUtxo],
        token,
        recipient,
        unwrap
      })
      progress('')
      return { receipt }
    }
  }

  return { fund, transfer, withdraw }
}
