const { formatUnits, Interface } = require('ethers')
const Utxo = require('./utxo')
const { bigNumToHex } = require('./utils')
const { PUBLIC_KEY_LENGTH } = require('@stablelib/x25519')
const { NONCE_LENGTH } = require('@stablelib/xchacha20poly1305')
// const { BigNumber } = require('@ethersproject/bignumber')
const ERC20_ABI = require('./erc20.abi.json')

const ZERO_PUBLIC_KEY = Buffer.alloc(PUBLIC_KEY_LENGTH)

module.exports = config => {
  /**
   * Lists historic transaction outputs by given key pair.
   *
   * @param {string|Contract} pool pool contract
   * @param {KeyPair} keypair spender's shielded account keypair
   * @param {string[]} peers Peer shielded addresses
  //  * @param {string} token ERC20 token address
   * @param {boolean} excludeSpent Exclude spent transactions
   * @param {boolean} excludeOthers Exclude others UTXOs
   * @param {number} from block height offset to start search from
   * @returns {{[key:string]: Utxo[]}} Unspent tx outputs
   */
  async function findUtxosH({
    pool = config.pool,
    keypair,
    peers,
    // tokens,
    // excludeSpent = true,
    // excludeOthers = true,
    // utxos after from blcok include a type field
    from = config.chainId === 100 ? 29897069 : 0
  }) {
    const loadedPeers = config.peers?.map(p => p.shieldedAddress || p) ?? []
    peers = peers ? peers.concat(loadedPeers) : loadedPeers

    const filter = pool.filters.NewCommitment()
    const events = await pool.queryFilter(filter, from)
    let utxoHistory = []

    events.forEach(async event => {
      let utxo
      // let peer
      try {
        const result = Utxo.decrypt(
          keypair,
          peers,
          event.args.encryptedOutput,
          Number(event.args.index)
        )
        utxo = result[0]
        utxo.peer = result[1]
      } catch (_) {} // eslint-disable-line no-empty
      if (!utxo) {
        return
      } else {
        if (utxo.amount.toString() === '0') {
          return
        }

        const commitment = bigNumToHex(utxo.getCommitment())
        const event = events.find(e => e.args.commitment === commitment)
        // console.log(event)

        const encryptedBuf = Buffer.from(
          event.args.encryptedOutput.replace('0x', ''),
          'hex'
        )
        const ephemeralPublicKey = Buffer.from(
          encryptedBuf.subarray(NONCE_LENGTH, NONCE_LENGTH + PUBLIC_KEY_LENGTH)
        )
        utxo.ephemeralPublicKey = ephemeralPublicKey.equals(ZERO_PUBLIC_KEY)
          ? null
          : ephemeralPublicKey

        utxoHistory.push({
          amount: utxo.amount,
          type: utxo.type,
          tx: event.transactionHash,
          blockNumber: event.blockNumber,
          keypair: utxo.keypair,
          ephemeralPublicKey: utxo.ephemeralPublicKey,
          note: utxo.note,
          token: utxo.token,
          peer: utxo.peer
        })
      }
    })

    utxoHistory = utxoHistory.filter(
      u => !(u.type === 2 && u.keypair.privkey && !!u.ephemeralPublicKey)
    )
    // return { ...u, receipt }
    utxoHistory = await Promise.all(
      utxoHistory.map(async u => {
        return {
          ...u,
          date: await config.provider.getBlock(u.blockNumber).then(b => b.date)
        }
      })
    )

    utxoHistory = await Promise.all(
      utxoHistory.map(async u => {
        let receipt
        const poolAddress = await config.pool.getAddress()
        if (u.type === 1 || u.type === 3) {
          receipt = await config.provider.getTransactionReceipt(u.tx)
          const ierc20 = new Interface(ERC20_ABI)
          const events = receipt.logs.map(l => {
            try {
              return ierc20.parseLog(l)
            } catch (_) {} // eslint-disable-line
          })
          receipt.events = events
          let transfer
          if (u.type === 1) {
            transfer = events.find(e => e?.args?.to === poolAddress)
          } else if (u.type === 3) {
            transfer = events.find(e => e?.args?.from === poolAddress)
          }
          receipt.events.transfer = transfer
          u.amount = transfer.args.value
        }
        return { ...u, receipt }
      })
    )

    utxoHistory = utxoHistory.map(u => {
      const shieldedTo = config.peers.find(p =>
        p.startsWith(bigNumToHex(u.keypair.pubkey))
      )
      if (u.type === 0) {
        u.type = 'bogus'
      } else if (u.type === 1) {
        u.type = 'fund'
      } else if (u.type === 2) {
        if (u.keypair.privkey) {
          u.type = 'inbound transfer'
        } else {
          u.type = 'outbound transfer'
        }
      } else if (u.type === 3) {
        u.type = 'withdraw'
      }
      return {
        ...u,
        amount: formatUnits(u.amount.toString(), 18),
        from:
          u.type === 'inbound transfer'
            ? u.peer
            : u.type === 'withdraw' || u.type === 'outbound transfer'
            ? keypair.address() // eslint-disable-line
            : u.receipt?.events.transfer.args.from, // eslint-disable-line
        to:
          u.type === 'fund' || u.type.endsWith('transfer')
            ? shieldedTo
            : u.receipt?.events.transfer.args.to,
        note: u.note.length > 1 ? Buffer.from(u.note).toString() : ''
      }
    })

    return utxoHistory
  }

  return findUtxosH
}
