import { default as ReactModal } from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Flex, Box, Image } from 'rebass'
import { Checkbox, Label } from '@rebass/forms'
import Button from './button'
import { dump, register } from './redux'
import { shorten } from './util'

export default function RegisterModal() {
  const dispatch = useDispatch()
  const {
    shieldedAddress,
    isRegistered,
    showRegisterModal,
    safes = [],
    safeAddress
  } = useSelector(state => state)
  return (
    <ReactModal
      isOpen={
        typeof showRegisterModal === 'boolean'
          ? showRegisterModal
          : isRegistered === false
      }
      contentLabel="register modal"
      appElement={document.getElementById('root')}
      className="responsive-modal"
    >
      <Flex
        style={{
          justifyContent: 'center',
          marginTop: window.innerHeight < 500 ? '0' : '20vh'
        }}
      >
        <Box
          sx={{
            background: '#fff',
            border: '0.1875em solid #000',
            boxShadow: '0 0.625em',
            padding: '0.625em',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: ['1em', '1.25em']
          }}
        >
          <Text sx={{ fontWeight: 'bold', marginBottom: '0.625em' }}>
            ®️ Register
          </Text>
          <Text>Publish your shielded address for peer discovery.</Text>
          {safes.length ? (
            <Text>
              Optionally, select a Circles profile you wish to register as an
              alias for your shielded address.
            </Text>
          ) : null}
          <Text style={{ marginBottom: '1.25em' }}>
            {' '}
            This action does not reveal any sensitive information.
          </Text>
          {safes.length ? (
            <Box sx={{ marginBottom: '1.25em' }}>
              <Text></Text>
              {safes.map(
                ({ userName, userAvatar, safeAddress: _safeAddress }) => {
                  if (!userAvatar) {
                    userAvatar = `https://robohash.org/${_safeAddress}.png`
                  }
                  return (
                    <Label
                      key={_safeAddress}
                      sx={{
                        cursor: 'pointer',
                        justifyContent: 'center',
                        padding: [0, '0 15vw', '0 25vw']
                      }}
                    >
                      <Box sx={{ width: '100%', display: 'inline-flex' }}>
                        <Checkbox
                          checked={safeAddress === _safeAddress}
                          sx={{ color: '#000' }}
                          onChange={e =>
                            dispatch(
                              dump({
                                safeAddress: e.target.checked
                                  ? _safeAddress
                                  : null
                              })
                            )
                          }
                        />
                        <Box>
                          {' '}
                          <Image
                            src={userAvatar}
                            sx={{
                              width: ['1.4em'],
                              borderRadius: 10,
                              verticalAlign: 'top',
                              margin: '0 0.3125em 0.3125em 0'
                            }}
                          ></Image>
                          <Text
                            sx={{
                              minWidth: ['3.75em', '5em'],
                              display: 'inline-block'
                            }}
                          >
                            {userName} {shorten(_safeAddress)}
                          </Text>
                        </Box>
                      </Box>
                    </Label>
                  )
                }
              )}
            </Box>
          ) : null}
          <Button
            disabled={!shieldedAddress}
            aria-label="Register"
            style={{
              margin: '0 0 0.625em 0',
              cursor: shieldedAddress ? 'pointer' : 'none'
            }}
            onClick={() => dispatch(register())}
          >
            Register
          </Button>
        </Box>
      </Flex>
    </ReactModal>
  )
}
