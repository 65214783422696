const Utxo = require('./utxo')
const { bigNumToHex } = require('./utils')

module.exports = config => {
  /**
   * Lists unspent transaction outputs by given key pair.
   *
   * @param {string|Contract} pool pool contract
   * @param {KeyPair} keypair spender's shielded account keypair
   * @param {string[]} peers Peer shielded addresses
   * @param {string} token ERC20 token address
   * @param {boolean} excludeSpent Exclude spent transactions
   * @param {boolean} excludeOthers Exclude others UTXOs
   * @param {number} from block height offset to start search from
   * @returns {{[key:string]: Utxo[]}} Unspent tx outputs
   */
  async function findUtxos({
    pool = config.pool,
    keypair,
    peers,
    tokens,
    excludeSpent = true,
    excludeOthers = true,
    from = config.chainId === 100 ? 29897069 : 0
  }) {
    const loadedPeers = config.peers?.map(p => p.shieldedAddress || p) ?? []
    peers = peers ? peers.concat(loadedPeers) : loadedPeers

    const filter = pool.filters.NewCommitment()
    const events = await pool.queryFilter(filter, from)
    const utxos = await Promise.all(
      events.map(async event => {
        let utxo
        try {
          // utxo = Utxo.decrypt(
          //   keypair,
          //   peers,
          //   event.args.encryptedOutput,
          //   Number(event.args.index)
          // )
          const result = Utxo.decrypt(
            keypair,
            peers,
            event.args.encryptedOutput,
            Number(event.args.index)
          )
          utxo = result[0]
        } catch (_) {} // eslint-disable-line no-empty
        if (!utxo) {
          return null
        } else {
          if (utxo.amount.toString() === '0') {
            return null
          }
          const utxoToken = '0x' + utxo.token.toString('hex').toLowerCase()
          if (!tokens.some(token => utxoToken === token.toLowerCase())) {
            return null
          }
          // .filter(utxo => utxo.keypair.privkey)
          if (excludeOthers && !utxo.keypair.privkey) {
            return null
          }
          if (utxo.index && utxo.keypair.privkey) {
            let nullifier = bigNumToHex(utxo.getNullifier(), 32)
            if (excludeSpent) {
              const isSpent = await pool.isSpent(nullifier)
              if (isSpent) {
                return null
              }
            }
          }
          return [utxoToken, utxo]
        }
      })
    )
      .then((sparse) /*[null,[utxoToken, utxo],...]*/ => sparse.filter(Boolean))
      .then((flat) /*[[utxoToken, utxo],...]*/ => {
        return flat.reduce((acc, [utxoToken, utxo]) => {
          if (Array.isArray(acc[utxoToken])) {
            acc[utxoToken].push(utxo)
          } else {
            acc[utxoToken] = [utxo]
          }
          return acc
        }, {})
      })

    return utxos
  }

  return findUtxos
}
