import { useState, useEffect } from 'react'
import { configureStore } from '@reduxjs/toolkit'
import { Contract, formatUnits, parseEther } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import { Box, Text } from 'rebass'
import bermuda from 'bermuda-bay-sdk'
import {
  shorten,
  copyToClipboard,
  getSigner,
  setIntervalX,
  fetchGelatoRelayTx,
  gelatoTaskUrl,
  prettierBalance,
  circlesSafesOf
} from './util'
import { TxAnchor, Anchor } from './anchor'
import MetaMask from './metamask'
import { TOKENS } from './constants'

const metamask = new MetaMask()
let sdk = bermuda('gnosis', {
  peers: [
    // '0x2716b597b46dd34fede789f11aa3bc71c8f84fae6e90a3c8a3e434b93d1eb897da27301857178b77bf5b4624ae1b27994f13e5ca0ab239fa9df7b11b56c30544',
    // '0x01d0479ec3eaf40203a2fae904e6d3edcab08cea2d00ae605bebcbbaa0ea5be3542f0c79d713d9ecc6f31b1f156f5bec2055a07f77c0ddf87759fe4c1a3e5a23',
    // '0x1c5f604917f6c9060d1ecf64c23ee16955a45a80d6bbd2df7de015174b2c146f84f980db976d6d65d34a2dc7e820866e9fab588b5cab7c789477c4b3afd29711'
  ]
})

export function useMyMetaMask() {
  const [chainId, setChainId] = useState(undefined)
  const [accounts, setAccounts] = useState([])
  useEffect(() => {
    metamask.provider.on('accountsChanged', accounts => setAccounts(accounts))
    metamask.provider.on('chainChanged', chainId => setChainId(Number(chainId)))
    metamask.provider
      .request({
        method: 'wallet_getPermissions'
      })
      .then(permissions => {
        if (permissions.length) {
          metamask.getAccounts().then(accounts => setAccounts(accounts))
        }
      })
    metamask.getChainId().then(chainId => setChainId(chainId))
    return async () => {}
  }, []) // doing `}, [accounts])` was bad
  return {
    isConnected: !!accounts.length,
    chainId,
    chainName:
      chainId === 100 ? 'gnosis' : chainId === 10200 ? 'chiado' : undefined,
    accounts,
    account: accounts?.[0]
  }
}

function onAccountsChanged(dispatch, getState, _accounts) {
  window.location.reload()
  // dispatch(
  //   dump({
  //     showConnectModal: true,
  //     showRegisterModal: false,
  //     shieldedPrivateKey: undefined,
  //     selectedMenu: null,
  //     shieldedBalance: {},
  //     standardBalance: {},
  //     shieldedAddress: null,
  //     showSecretSeedInput: false,
  //     shieldedAccountSeed: null,
  //     isRegistered: false
  //   })
  // )

  // loadBalance()(dispatch, getState)
}

async function onChainChanged(dispatch, getState, chainId) {
  chainId = Number(chainId)
  const chainName =
    chainId === 100 ? 'gnosis' : chainId === 10200 ? 'chiado' : undefined
  if (chainName !== 'gnosis') {
    dispatch(dump({ showChainModal: true, selectedChain: 'gnosis' }))
    return
  }

  sdk = bermuda(chainName, { provider: sdk.config.provider })
  const gelatoRelayFeeEstimates = Object.fromEntries(
    await Promise.all(
      TOKENS.map(async token => [
        token.symbol,
        await sdk.utils
          .gelatoRelayFeeEstimate(token.address[chainName], chainId)
          .then(String)
          .catch(_ => undefined)
      ])
    )
  )
  const registrationTerm = await sdk.registry.getTerm().catch(_err => undefined)
  dispatch(
    dump({
      selectedChain: chainName,
      gelatoRelayFeeEstimates,
      registrationTerm
    })
  )
  sdk.utils.onprogress(desc => dispatch(dump({ progress: desc })))
  sdk.registry.load()
}

export function estimateGelatoRelayFees() {
  return async function (dispatch) {
    const chainId = await metamask.getChainId()
    const chainName = await metamask.getChainName()

    const entries = await Promise.all(
      TOKENS.map(async token => {
        return [
          token.symbol,
          await sdk.utils
            .gelatoRelayFeeEstimate(token.address[chainName], chainId)
            .then(String)
            .catch(_ => undefined)
        ]
      })
    )

    const gelatoRelayFeeEstimates = Object.fromEntries(entries)

    dispatch(dump({ gelatoRelayFeeEstimates }))
  }
}

export function connect(account) {
  return async function (dispatch, getState) {
    metamask.provider.on(
      'accountsChanged',
      onAccountsChanged.bind(null, dispatch, getState)
    )
    metamask.provider.on(
      'chainChanged',
      onChainChanged.bind(null, dispatch, getState)
    )
    if (!account) {
      await metamask.connect()
    }
    const chainId = await metamask.getChainId()
    const chainName = await metamask.getChainName()

    if (chainName !== 'gnosis') {
      dispatch(
        dump({
          showChainModal: true,
          selectedChain: 'gnosis',
          showConnectModal: true,
          showSecretSeedInput: false
        })
      )
      return
    }
    dispatch(
      dump({
        showConnectModal: true,
        showSecretSeedInput: false
      })
    )
    const gelatoRelayFeeEstimates = Object.fromEntries(
      await Promise.all(
        TOKENS.map(async token =>
          token.address[chainName]
            ? [
                token.symbol,
                await sdk.utils
                  .gelatoRelayFeeEstimate(token.address[chainName], chainId)
                  .then(String)
                  .catch(_ => undefined)
              ]
            : []
        )
      )
    )
    const registrationTerm = await sdk.registry.getTerm()
    dispatch(dump({ gelatoRelayFeeEstimates, registrationTerm }))

    sdk.utils.onprogress(desc => dispatch(dump({ progress: desc })))

    await sdk.registry.load()

    loadBalance()(dispatch, getState)
  }
}

export function disconnect() {
  return async function (dispatch, _getState) {
    metamask.provider.removeAllListeners('accountsChanged')
    metamask.provider.removeAllListeners('chainChanged')
    // TODO not yet killing sdk connections
    dispatch(
      dump({
        shieldedPrivateKey: undefined,
        shieldedBalance: {},
        standardBalance: {},
        shieldedAddress: null,
        showConnectModal: false,
        showSecretSeedInput: false,
        shieldedAccountSeed: null,
        selectedMenu: null,
        isRegistered: null
      })
    )
  }
}

export function switchNetwork() {
  return async function (dispatch, getState) {
    const { selectedChain, customRpcUrl } = getState()
    sdk = bermuda(selectedChain, {
      provider: customRpcUrl == undefined ? sdk.config.provider : customRpcUrl
    })
    await metamask.switchNetwork(selectedChain)
    const registrationTerm = await sdk.registry
      .getTerm()
      .catch(_err => undefined)
    dispatch(dump({ showChainModal: false, registrationTerm }))
    sdk.utils.onprogress(desc => dispatch(dump({ progress: desc })))
    sdk.registry.load()
  }
}

export function maxout(shielded = false) {
  return async function (dispatch, getState) {
    const { selectedToken, selectedTokenAddress } = getState()
    const token = TOKENS.find(({ symbol }) => symbol === selectedToken)
    if (!shielded) {
      const account = await metamask.getAccounts().then(accounts => accounts[0])
      const contract = new Contract(selectedTokenAddress, sdk.utils.ERC20_ABI, {
        provider: sdk.config.provider
      })
      const balance = await contract.balanceOf(account).then(String)
      dispatch(
        dump({ tokenAmount: formatUnits(balance, token.decimals).slice(0, 18) })
      )
    } else {
      const { shieldedBalance, selectedToken } = getState()

      dispatch(
        dump({
          shieldedTokenAmount: formatUnits(
            shieldedBalance[selectedToken],
            token.decimals
          ).slice(0, 18)
        })
      )
    }
  }
}

export function register() {
  return async function (dispatch, getState) {
    dispatch(dump({ dots: true, progress: 'Registering' }))
    const { shieldedAddress, name, safeAddress } = getState()
    const signer = await getSigner()
    sdk.registry
      .register(signer, shieldedAddress, name, safeAddress)
      .catch(err => {
        console.error(err)
        dispatch(
          dump({
            modalTitle: '🔥 Error',
            modalText: (
              <Box>
                Registration failed <TxAnchor hash={err?.receipt?.hash} />
              </Box>
            ),
            progress: '',
            dots: false
          })
        )
      })
      .then(response => {
        dispatch(
          dump({
            modalTitle: '💎 Success',
            modalText: (
              <Box>
                Registered{name ? ` ${name} as alias for ` : ' '}
                <span
                  style={{ cursor: 'grab' }}
                  onClick={copyToClipboard.bind(null, shieldedAddress)}
                >
                  {shorten(shieldedAddress)}
                </span>{' '}
                <TxAnchor hash={response?.hash} />
              </Box>
            ),
            progress: '',
            dots: false,
            showRegisterModal: false
          })
        )
      })
  }
}

export function renew() {
  return async function (dispatch, getState) {
    dispatch(dump({ dots: true }))
    const { name, shieldedAddress } = getState()
    const signer = await getSigner()
    sdk.registry
      .renew(signer, name)
      .catch(err => {
        console.error(err)
        dispatch(
          dump({
            modalTitle: '🔥 Error',
            modalText: (
              <Box>
                Renewal failed <TxAnchor hash={err?.receipt?.hash} />
              </Box>
            ),
            dots: false
          })
        )
      })
      .then(({ receipt }) => {
        dispatch(
          dump({
            modalTitle: '💎 Success',
            modalText: (
              <Box>
                Renewed {name} as alias for{' '}
                <span
                  style={{ cursor: 'grab' }}
                  onClick={copyToClipboard.bind(null, shieldedAddress)}
                >
                  {shorten(shieldedAddress)}
                </span>{' '}
                for another year
                <TxAnchor hash={receipt?.hash} />
              </Box>
            ),
            dots: false
          })
        )
      })
  }
}

export function nameChange(name) {
  return async function (dispatch, _getState) {
    if (name?.length >= 7 && name?.endsWith('.bay')) {
      const registrationFee = await sdk.registry.getFee(name)
      dispatch(dump({ registrationFee }))
    }
    dispatch(dump({ name }))
  }
}
//TODO remove params from fund transfer andwithdraw and have them read all that from state instead
export function fund(amount, recipient) {
  return async function (dispatch, getState) {
    dispatch(dump({ dots: true }))
    let {
      shieldedAddress,
      shieldedPrivateKey,
      fundShieldedRecipient,
      selectedToken,
      selectedTokenAddress
    } = getState()
    sdk.utils.progress('Resolving recipient')
    const _recipient = await sdk.registry.resolveShieldedAddress(recipient)
    fundShieldedRecipient = _recipient
    if (!_recipient) {
      dispatch(
        dump({
          modalTitle: '4🙃4',
          modalText: (
            <Text> Unable to resolve {recipient} to a shielded address.</Text>
          ),
          dots: false
        })
      )
      sdk.utils.progress('')
      return
    }
    recipient = _recipient
    sdk.utils.progress('Checking allowance')
    const _amount = parseEther(amount)
    const signer = await getSigner()
    const poolAddress = await sdk.config.pool.getAddress()
    const token = new Contract(selectedTokenAddress, sdk.utils.ERC20_ABI, {
      provider: sdk.config.provider
    })
    const allowance = await token.allowance(
      await signer.getAddress(),
      poolAddress
    )
    let approveReceipt
    if (allowance < _amount) {
      approveReceipt = await token
        .connect(signer)
        .approve(poolAddress, _amount)
        .then(res => {
          return sdk.config.provider.waitForTransaction(res.hash)
        })
    }
    if (approveReceipt && approveReceipt.status === 0) {
      dispatch(
        dump({
          modalTitle: '🔥 Error',
          modalText: (
            <Box>
              Allowance approval of {amount}
              {selectedToken} for{' '}
              <span
                style={{ cursor: 'grab' }}
                onClick={copyToClipboard.bind(null, poolAddress)}
              >
                {shorten(poolAddress)}
              </span>{' '}
              failed <TxAnchor hash={approveReceipt?.hash} />
            </Box>
          ),
          dots: false
        })
      )
      sdk.utils.progress('')
      return
    }
    await sdk.omnipool
      .fund({
        pool: sdk.config.pool,
        signer,
        amount,
        recipient,
        token: selectedTokenAddress
      })
      .catch(err => {
        console.error(err)
        dispatch(
          dump({
            modalTitle: '🔥 Error',
            modalText: (
              <Box>
                Funding failed <TxAnchor hash={err?.receipt?.hash} />
              </Box>
            ),
            dots: false
          })
        )
      })
      .then(async ({ receipt }) => {
        const recipientName = await sdk.registry.nameOfShieldedAddress(
          fundShieldedRecipient || shieldedAddress
        )
        dispatch(
          dump({
            modalTitle: '💎 Success',
            modalText: (
              <Box>
                Funded{' '}
                <span
                  style={{ cursor: 'grab' }}
                  onClick={copyToClipboard.bind(
                    null,
                    fundShieldedRecipient || shieldedAddress
                  )}
                >
                  {shorten(
                    recipientName || fundShieldedRecipient || shieldedAddress
                  )}
                </span>{' '}
                {prettierBalance(amount)}
                {selectedToken} <TxAnchor hash={receipt?.hash} />
              </Box>
            ),
            dots: false
          })
        )
        dispatch(deriveShieldedAccount(shieldedPrivateKey))
      })
  }
}

export function transfer(amount, recipient) {
  return async function (dispatch, getState) {
    dispatch(dump({ dots: true }))
    const {
      selectedToken,
      selectedTokenAddress,
      shieldedPrivateKey,
      transferNote,
      transferUseGelatoRelay
    } = getState()
    sdk.utils.progress('Resolving recipient')
    const _recipient = await sdk.registry.resolveShieldedAddress(recipient)
    if (!_recipient) {
      dispatch(
        dump({
          modalTitle: '4🙃4',
          modalText: (
            <Text> Unable to resolve {recipient} to a shielded address.</Text>
          ),
          dots: false
        })
      )
      sdk.utils.progress('')
      return
    }
    recipient = _recipient
    const keypair = new sdk.KeyPair(shieldedPrivateKey)
    const signer = await getSigner()
    let gelatoFee
    // if (transferUseGelatoRelay) {
    //   sdk.utils.progress('Fetching Gelato Relay fee')
    //   gelatoFee = await sdk.utils.gelatoRelayFeeEstimate(selectedTokenAddress)
    // }
    await sdk.omnipool
      .transfer({
        pool: sdk.config.pool,
        signer,
        keypair,
        amount,
        recipient,
        token: selectedTokenAddress,
        note: transferNote,
        useGelatoRelay: transferUseGelatoRelay
      })
      .catch(err => {
        console.error(err)
        dispatch(
          dump({
            modalTitle: '🔥 Error',
            modalText: (
              <Box>
                Transfer failed <TxAnchor hash={err?.receipt?.hash} />
              </Box>
            ),
            dots: false
          })
        )
      })
      .then(async ({ receipt, taskId }) => {
        const recipientName = await sdk.registry.nameOfShieldedAddress(
          recipient
        )
        if (!taskId) {
          dispatch(
            dump({
              modalTitle: '💎 Success',
              modalText: (
                <Box>
                  Transfered {prettierBalance(amount)}
                  {selectedToken} to{' '}
                  <span
                    style={{ cursor: 'grab' }}
                    onClick={copyToClipboard.bind(null, recipient)}
                  >
                    {shorten(recipientName || recipient)}
                  </span>{' '}
                  <TxAnchor hash={receipt?.hash} />
                </Box>
              ),
              dots: false
            })
          )
          dispatch(deriveShieldedAccount(shieldedPrivateKey))
        } else {
          const tx = await fetchGelatoRelayTx(taskId).catch(console.error)
          if (!tx) {
            dispatch(
              dump({
                modalTitle: '🔥 Error',
                modalText: (
                  <Box>
                    Transfer via Gelato Relay failed{' '}
                    <Anchor
                      href={gelatoTaskUrl(taskId)}
                      text={shorten(taskId)}
                    />
                    . Please try again.
                  </Box>
                ),
                dots: false
              })
            )
          } else {
            dispatch(
              dump({
                modalTitle: '💎 Success',
                modalText: (
                  <Box>
                    Transfered {prettierBalance(amount)}
                    {selectedToken} to{' '}
                    <span
                      style={{ cursor: 'grab' }}
                      onClick={copyToClipboard.bind(null, recipient)}
                    >
                      {shorten(recipientName || recipient)}
                    </span>{' '}
                    via Gelato <TxAnchor hash={tx} /> 🍦
                  </Box>
                ),
                dots: false
              })
            )
            setIntervalX(
              () => dispatch(deriveShieldedAccount(shieldedPrivateKey)),
              5000,
              6
            )
          }
        }
      })
  }
}

export function withdraw(amount, recipient) {
  return async function (dispatch, getState) {
    dispatch(dump({ dots: true }))
    const {
      selectedToken,
      selectedTokenAddress,
      shieldedPrivateKey,
      withdrawUseGelatoRelay,
      withdrawUnwrap
    } = getState()
    sdk.utils.progress('Resolving recipient')
    const _recipient = await sdk.registry.resolveNativeAddress(recipient)
    if (!_recipient) {
      dispatch(
        dump({
          modalTitle: '4🙃4',
          modalText: (
            <Text>Unable to resolve {recipient} to a native address.</Text>
          ),
          dots: false
        })
      )
      sdk.utils.progress('')
      return
    }
    recipient = _recipient
    const keypair = new sdk.KeyPair(shieldedPrivateKey)
    const signer = await getSigner()
    let gelatoFee
    // if (withdrawUseGelatoRelay) {
    //   sdk.utils.progress('Fetching Gelato Relay fee')
    //   gelatoFee = await sdk.utils.gelatoRelayFeeEstimate(selectedTokenAddress)
    // }
    const chainName = await metamask.getChainName()
    await sdk.omnipool
      .withdraw({
        pool: sdk.config.pool,
        signer,
        keypair,
        amount,
        recipient,
        token: selectedTokenAddress.toLowerCase(),
        // can't use gelato on chiado for gno
        useGelatoRelay:
          chainName === 'chiado' && selectedToken === 'GNO'
            ? undefined
            : withdrawUseGelatoRelay,
        gelatoFee,
        // can only unwrap wxdai
        unwrap: selectedToken === 'WXDAI' ? withdrawUnwrap : undefined
      })
      .catch(err => {
        console.error(err)
        dispatch(
          dump({
            modalTitle: '🔥 Error',
            modalText: (
              <Box>
                Withdrawal failed <TxAnchor hash={err?.receipt?.hash} />
              </Box>
            ),
            dots: false
          })
        )
      })
      .then(async ({ receipt, taskId }) => {
        const recipientName = await sdk.registry.lookupEnsName(recipient)
        if (!taskId) {
          dispatch(
            dump({
              modalTitle: '💎 Success',
              modalText: (
                <Box>
                  Withdrew {prettierBalance(amount)}
                  {selectedToken} to{' '}
                  <span
                    style={{ cursor: 'grab' }}
                    onClick={copyToClipboard.bind(null, recipient)}
                  >
                    {shorten(recipientName || recipient)}
                  </span>{' '}
                  <TxAnchor hash={receipt?.hash} />
                </Box>
              ),
              dots: false
            })
          )
          dispatch(deriveShieldedAccount(shieldedPrivateKey))
        } else {
          const tx = await fetchGelatoRelayTx(taskId).catch(console.error)
          if (!tx) {
            dispatch(
              dump({
                modalTitle: '🔥 Error',
                modalText: (
                  <Box>
                    Withdrawal via Gelato Relay failed{' '}
                    <Anchor
                      href={gelatoTaskUrl(taskId)}
                      text={shorten(taskId)}
                    />
                    . Please try again.
                  </Box>
                ),
                dots: false
              })
            )
          } else {
            dispatch(
              dump({
                modalTitle: '💎 Success',
                modalText: (
                  <Box>
                    Withdrew {prettierBalance(amount)}
                    {selectedToken} to{' '}
                    <span
                      style={{ cursor: 'grab' }}
                      onClick={copyToClipboard.bind(null, recipient)}
                    >
                      {shorten(recipientName || recipient)}
                    </span>{' '}
                    via Gelato <TxAnchor hash={tx} /> 🍦
                  </Box>
                ),
                dots: false
              })
            )
            setIntervalX(
              () => dispatch(deriveShieldedAccount(shieldedPrivateKey)),
              5000,
              6
            )
          }
        }
      })
  }
}

export function loadBalance() {
  return async function (dispatch, getState) {
    const {
      selectedTokenAddress,
      isLoadingUtxos,
      selectedToken,
      shieldedPrivateKey
    } = getState()
    if (!isLoadingUtxos) {
      dispatch(dump({ isLoadingUtxos: true }))
      const keypair = new sdk.KeyPair(shieldedPrivateKey)
      const utxos = await sdk.utils.findUtxos({
        pool: sdk.config.pool,
        keypair,
        tokens: [selectedTokenAddress]
      })
      const spend = sdk.utils.sumAmounts(
        utxos[selectedTokenAddress.toLowerCase()]
      )
      dispatch(
        dump({
          shieldedBalance: {
            [selectedToken]: spend.toHexString()
          },
          isLoadingUtxos: false
        })
      )
    }

    const token = TOKENS.find(({ symbol }) => symbol === selectedToken)
    const balance = await new Contract(
      token.address[await metamask.getChainName()],
      sdk.utils.ERC20_ABI,
      sdk.config.provider
    )
      .balanceOf(await getSigner().then(s => s.address))
      .then(BigNumber.from)
    dispatch(
      dump({
        standardBalance: {
          [selectedToken]: balance.toHexString()
        }
      })
    )
  }
}

export function deriveShieldedAccount(shieldedPrivateKey) {
  return async function (dispatch, getState) {
    const { selectedToken, selectedTokenAddress } = getState()
    const keypair = new sdk.KeyPair(shieldedPrivateKey)
    const shieldedAddress = keypair.address()
    const isRegistered = await sdk.registry.isRegistered(shieldedAddress)
    const eoa = await getSigner().then(s => s.address)
    let name
    let registrationExpiry
    let registrationFee
    let safes
    if (isRegistered) {
      const name = await sdk.registry.nameOfShieldedAddress(shieldedAddress)
      if (name) {
        registrationExpiry = await sdk.registry.expiryOf(name)
        registrationFee = await sdk.registry.getFee(name)
      }
    } else {
      // check if eoa has a circles safe - might be undefined
      safes = await circlesSafesOf(eoa)
    }

    const ensName = await sdk.registry
      .lookupEnsName(eoa)
      .then(n => n || undefined)

    dispatch(
      dump({
        shieldedAddress,
        isRegistered,
        name,
        ensName,
        registrationExpiry,
        registrationFee,
        safes
      })
    )
    const utxos = await sdk.utils.findUtxos({
      pool: sdk.config.pool,
      keypair,
      tokens: [selectedTokenAddress]
    })
    const spend = sdk.utils.sumAmounts(
      utxos[selectedTokenAddress.toLowerCase()]
    )
    dispatch(
      dump({
        shieldedBalance: {
          [selectedToken]: spend.toHexString()
        },
        dots: false
      })
    )
  }
}

export function history() {
  return async function (dispatch, getState) {
    dispatch(dump({ dots: true, progress: 'Loading transaction history' }))
    const { shieldedPrivateKey } = getState()
    const utxosh = await sdk.utils.findUtxosH({
      pool: sdk.config.pool,
      keypair: new sdk.KeyPair(shieldedPrivateKey)
      // tokens: [selectedTokenAddress]
    })
    dispatch(dump({ utxosh, dots: false, progress: '' }))
  }
}

// export function resolveNames({nativeAddresses, shieldedAddresses}) {
//   return async function (dispatch, getState) {
//     let nativeNames = await Promise.all(nativeAddresses.map(a => sdk.registry.lookupEnsName(a).then(n => [a, n || a])))
//     nativeNames = Object.fromEntries(nativeNames)

//     let shieldedNames = await Promise.all(shieldedAddresses.map(a =>   sdk.registry.lookupEnsName(a).then(n => [a, n || a])   ))
//     shieldedNames = Object.fromEntries(shieldedNames)

//   }
// }

const DUMP = 'DUMP'

export function dump(props) {
  return { type: DUMP, ...props }
}

export const store = configureStore({
  reducer(
    state = {
      transferUseGelatoRelay: false,
      withdrawUseGelatoRelay: false,
      withdrawUnwrap: false,
      selectedChain: 'gnosis',
      selectedToken: 'HoG',
      selectedTokenAddress: '0x6de572FaA138048CE8142c4A206eB09a8Ec39E45',
      shieldedBalance: {},
      standardBalance: {}
    },
    { type, ...props }
  ) {
    let withdrawUseGelatoRelay =
      props?.withdrawUseGelatoRelay ?? state.withdrawUseGelatoRelay
    let selectedTokenAddress = state.selectedTokenAddress
    if (props?.selectedToken) {
      selectedTokenAddress = TOKENS.find(
        ({ symbol }) => symbol === props?.selectedToken
      )?.address?.[props?.selectedChain || state.selectedChain]
    }
    switch (type) {
      case DUMP:
        return {
          ...state,
          ...props,
          withdrawUseGelatoRelay,
          selectedTokenAddress,
          shieldedBalance: {
            ...state.shieldedBalance,
            ...props.shieldedBalance
          },
          standardBalance: {
            ...state.standardBalance,
            ...props.standardBalance
          }
        }
      default:
        return state
    }
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['DUMP'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['modalText'],
        // Ignore these paths in the state
        ignoredPaths: ['modalText']
      }
    })
})
