const MerkleTree = require('fixed-merkle-tree')
const { BigNumber } = require('@ethersproject/bignumber')
const Utxo = require('./utxo')
const { prove2, prove16 } = require('./groth16')
const {
  FIELD_SIZE,
  bigNumToHex,
  getExtDataHash,
  poseidonHash2,
  shuffle,
  progress
} = require('./utils')

module.exports = config => {
  async function buildMerkleTree({ pool = config.pool }) {
    progress('Reconstructing Merkle tree')
    const filter = pool.filters.NewCommitment()
    const events = await pool.queryFilter(filter, 0)

    const leaves = events
      .sort((a, b) => Number(a.args.index) - Number(b.args.index))
      .map(e => bigNumToHex(e.args.commitment))

    return new MerkleTree(config.merkleTreeHeight, leaves, {
      hashFunction: poseidonHash2
    })
  }

  async function getProof({
    inputs,
    outputs,
    tree,
    extAmount,
    fee,
    recipient,
    relayer,
    unwrap,
    token,
    keyPair
  }) {
    progress('Generating zk-SNARK proof')
    inputs = shuffle(inputs)
    outputs = shuffle(outputs)

    let inputMerklePathIndices = []
    let inputMerklePathElements = []

    for (const input of inputs) {
      if (input.amount > 0) {
        input.index = tree.indexOf(bigNumToHex(input.getCommitment()))
        if (input.index < 0) {
          throw new Error(
            `Input commitment ${bigNumToHex(
              input.getCommitment()
            )} was not found`
          )
        }
        inputMerklePathIndices.push(input.index)
        inputMerklePathElements.push(tree.path(input.index).pathElements)
      } else {
        inputMerklePathIndices.push(0)
        inputMerklePathElements.push(new Array(tree.levels).fill(0))
      }
    }
    // console.log(
    //   '$$$$$ outputs[0].encryptEphemeral',
    //   outputs[0].encryptEphemeral
    // )
    // console.log(
    //   '$$$$$ outputs[1].encryptEphemeral',
    //   outputs[1].encryptEphemeral
    // )

    const extData = {
      recipient: bigNumToHex(recipient, 20),
      extAmount: bigNumToHex(extAmount),
      relayer: bigNumToHex(relayer, 20),
      fee: bigNumToHex(fee),
      encryptedOutput1: outputs[0].encryptEphemeral
        ? outputs[0].encrypt(undefined)
        : outputs[0].encrypt(keyPair),
      encryptedOutput2: outputs[1].encryptEphemeral
        ? outputs[1].encrypt(undefined)
        : outputs[1].encrypt(keyPair),
      unwrap,
      token
    }

    const extDataHash = getExtDataHash(extData)
    let input = {
      root: tree.root(),
      inputNullifier: inputs.map(x => x.getNullifier()),
      outputCommitment: outputs.map(x => x.getCommitment()),
      publicAmount: BigNumber.from(extAmount)
        .sub(fee)
        .add(FIELD_SIZE)
        .mod(FIELD_SIZE)
        .toString(),
      extDataHash,

      // data for 2 transaction inputs
      inAmount: inputs.map(x => x.amount),
      inPrivateKey: inputs.map(x => x.keypair.privkey),
      inBlinding: inputs.map(x => x.blinding),
      inToken: inputs.map(x => x.tokenCommit),
      inNote: inputs.map(x => x.noteCommit),
      inPathIndices: inputMerklePathIndices,
      inPathElements: inputMerklePathElements,

      // data for 2 transaction outputs
      outAmount: outputs.map(x => x.amount),
      outBlinding: outputs.map(x => x.blinding),
      outPubkey: outputs.map(x => x.keypair.pubkey),
      outToken: outputs.map(x => x.tokenCommit),
      outNote: outputs.map(x => x.noteCommit)
    }

    let proof
    if (inputs.length === 2) {
      proof = await prove2(input, config.zkAssetsBaseUrl)
    } else if (inputs.length === 16) {
      proof = await prove16(input, config.zkAssetsBaseUrl)
    }

    const args = {
      proof,
      root: bigNumToHex(input.root),
      inputNullifiers: inputs.map(x => bigNumToHex(x.getNullifier())),
      outputCommitments: outputs.map(x => bigNumToHex(x.getCommitment())),
      publicAmount: bigNumToHex(input.publicAmount),
      extDataHash: bigNumToHex(extDataHash)
    }

    return {
      extData,
      args
    }
  }

  async function prepareTransact({
    pool = config.pool,
    inputs = [],
    outputs = [],
    fee = 0,
    recipient = 0,
    relayer = 0,
    unwrap = false,
    token
  }) {
    progress('Preparing transaction')
    if (inputs.length > 16 || outputs.length > 2) {
      throw new Error('Incorrect inputs/outputs count')
    }
    // console.log(
    //   '$$$$$',
    //   !inputs.length ? 'FUNDING' : recipient ? 'WITHDRAWAL' : 'TRANSFER'
    // )
    const nonZeroUtxo = inputs.find(
      input => !input.amount.eq(BigNumber.from(0))
    )
    const keyPair = nonZeroUtxo?.keypair

    while (inputs.length !== 2 && inputs.length < 16) {
      inputs.push(new Utxo({ amount: 0, token }))
    }
    while (outputs.length < 2) {
      outputs.push(new Utxo({ amount: 0, token }))
    }

    let extAmount = BigNumber.from(fee)
      .add(outputs.reduce((sum, x) => sum.add(x.amount), BigNumber.from(0)))
      .sub(inputs.reduce((sum, x) => sum.add(x.amount), BigNumber.from(0)))

    const { args, extData } = await getProof({
      inputs,
      outputs,
      tree: await buildMerkleTree({ pool }),
      extAmount,
      fee,
      recipient,
      relayer,
      unwrap,
      token,
      keyPair
    })

    return {
      args,
      extData
    }
  }

  async function transact({ pool, ...rest }) {
    const { args, extData } = await prepareTransact({ pool, ...rest })
    progress('Dispatching transaction')
    return pool
      .transact(args, extData, { gasLimit: 2e6 })
      .then(res => {
        return config.provider.waitForTransaction(res.hash)
      })
      .then(receipt => {
        if (receipt.status === 0) {
          let err = new Error('transact failed')
          err.receipt = receipt
          throw err
        }
        return receipt
      })
  }
  return {
    buildMerkleTree,
    getProof,
    prepareTransact,
    transact
  }
}
