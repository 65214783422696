const { BigNumber } = require('@ethersproject/bignumber')
const { randomBigNum, poseidonHash, bigNumToBuf } = require('./utils')
const KeyPair = require('./keypair')

module.exports = class Utxo {
  /** Initialize a new UTXO - unspent transaction output or input. Note, a full TX consists of 2/16 inputs and 2 outputs
   *
   * @param {BigNumber | BigInt | number | string} amount UTXO amount
   * @param {BigNumber | BigInt | number | string} blinding Blinding factor
   * @param {KeyPair} keypair
   * @param {string|null} token Token address
   * @param {number|null} index UTXO index in the merkle tree
   * @param {string|null} note Arbitrary note
   * @param {boolean|undefined} encryptEphemeral Encrypt the utxo with an ephemeral x25519 keypair
   */
  constructor({
    amount = 0,
    keypair = new KeyPair(),
    blinding = randomBigNum(),
    index = null,
    token = null,
    note = null,
    encryptEphemeral = false,
    type = 0
  } = {}) {
    this.amount = BigNumber.from(amount)
    this.blinding = BigNumber.from(blinding)
    this.keypair = keypair
    this.index = index
    if (token) {
      this.token = Buffer.from(token.replace('0x', ''), 'hex')
      this.tokenCommit = BigNumber.from(token)
    } else {
      this.token = Buffer.from('0x' + '0'.repeat(40))
      this.tokenCommit = BigNumber.from(0)
    }
    if (note) {
      this.note = Buffer.from(note, 'utf8')
      this.noteCommit = BigNumber.from('0x' + this.note.toString('hex'))
    } else {
      this.note = Buffer.from([0])
      this.noteCommit = BigNumber.from(0)
    }
    this.encryptEphemeral = encryptEphemeral
    this.type = type
  }

  /**
   * Returns commitment for this UTXO
   *
   * @returns {BigNumber}
   */
  getCommitment() {
    if (!this._commitment) {
      this._commitment = poseidonHash([
        this.amount,
        this.keypair.pubkey,
        this.blinding,
        poseidonHash([this.tokenCommit, this.noteCommit])
      ])
    }
    return this._commitment
  }

  /**
   * Returns nullifier for this UTXO
   *
   * @returns {BigNumber}
   */
  getNullifier() {
    if (!this._nullifier) {
      if (
        this.amount > 0 &&
        (this.index === undefined ||
          this.index === null ||
          this.keypair.privkey === undefined ||
          this.keypair.privkey === null)
      ) {
        throw new Error(
          'Can not compute nullifier without utxo index or private key'
        )
      }
      const signature = this.keypair.privkey
        ? this.keypair.sign(this.getCommitment(), this.index || 0)
        : 0
      this._nullifier = poseidonHash([
        this.getCommitment(),
        this.index || 0,
        signature
      ])
    }
    return this._nullifier
  }

  /**
   * Encrypt UTXO data using the current keypair
   *
   * @param {Uint8Array} spendingKeyPair Spending curve25519 key pair
   * @returns {string} `0x`-prefixed hex string with data
   */
  encrypt(spendingKeyPair) {
    const bytes = Buffer.concat([
      bigNumToBuf(this.amount, 31),
      bigNumToBuf(this.blinding, 31),
      bigNumToBuf(this.keypair.pubkey, 32),
      this.token,
      bigNumToBuf(this.type, 1),
      this.note
    ])
    return '0x' + this.keypair.encrypt(spendingKeyPair, bytes).toString('hex')
  }

  /**
   * Decrypt a UTXO
   *
   * @param {KeyPair} keypair keypair used to decrypt
   * @param {string[]} peers Peer shielded addresses
   * @param {string} data hex string with data
   * @param {number} index UTXO index in merkle tree
   * @returns {[Utxo, string]} [utxo, peerShieldedAddress]
   */
  static decrypt(keypair, peers, data, index) {
    data = data.replace('0x', '')
    peers = peers || []
    const _peers = peers.map(peer =>
      Buffer.from(peer.replace('0x', '').slice(64, 128), 'hex')
    )
    const [buf, x25519PeerPublicKey] = keypair.decrypt(
      _peers,
      Buffer.from(data, 'hex')
    )
    const peer = peers.find(p =>
      p.endsWith(Buffer.from(x25519PeerPublicKey).toString('hex'))
    )

    if (!buf) {
      throw Error('Utxo: decryption failure')
    }
    const owner = '0x' + buf.subarray(62, 94).toString('hex')
    const _keyPair =
      owner === keypair.address().slice(0, 66)
        ? keypair
        : KeyPair.fromString(owner + '0'.repeat(64))
    return [
      new Utxo({
        amount: BigNumber.from('0x' + buf.subarray(0, 31).toString('hex')),
        blinding: BigNumber.from('0x' + buf.subarray(31, 62).toString('hex')),
        keypair: _keyPair,
        token: '0x' + buf.subarray(94, 114).toString('hex'),
        type: buf[114],
        note: buf.subarray(115, buf.length).toString('utf8') || null,
        index
      }),
      peer
    ]
  }
}
