import React from 'react'
import { Flex, Box, Text } from 'rebass'
import { Input, Checkbox, Label } from '@rebass/forms'
import { useDispatch, useSelector } from 'react-redux'
import Button from './button'
import ShieldedTokenInput from './shielded-token-input'
import { dump, useMyMetaMask, withdraw } from './redux'
import { formatUnits, parseUnits } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import { TOKENS } from './constants'
import { gte } from './util'

function validate(recipient, shieldedAmount, shieldedBalance) {
  return (
    !!recipient &&
    /^\d+(\.\d+)?$/.test(shieldedAmount) &&
    gte(shieldedBalance, shieldedAmount) &&
    Number(shieldedAmount) !== 0
  )
}

export default function Withdraw() {
  const { account } = useMyMetaMask()
  const {
    withdrawRecipient,
    shieldedTokenAmount,
    withdrawUseGelatoRelay,
    shieldedAddress,
    selectedToken,
    withdrawUnwrap,
    gelatoRelayFeeEstimates,
    shieldedBalance
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const valid = validate(
    withdrawRecipient,
    shieldedTokenAmount,
    shieldedBalance[selectedToken]
  )
  // const token = TOKENS.find(({ symbol }) => symbol === selectedToken)
  // const gelatoFee = BigNumber.from(
  //   String(gelatoRelayFeeEstimates[selectedToken] || 0)
  // )
  // const tokenAmount = shieldedTokenAmount
  //   ? BigNumber.from(String(parseUnits(pretttierBalance(shieldedTokenAmount), token.decimals)))
  //   : BigNumber.from(0)
  // const total = formatUnits(
  //   String(withdrawUseGelatoRelay ? tokenAmount.add(gelatoFee) : tokenAmount),
  //   token.decimals
  // ).slice(0, 6)

  return (
    <Box
      sx={{
        maxWidth: '24em',
        border: '0.1875em solid #000',
        boxShadow: '0 0.625em',
        padding: '0.625em'
      }}
    >
      <Flex
        sx={{
          fontSize: [24, 26, 28],
          fontWeight: 'bold',
          marginBottom: '0.625em',
          flexWrap: 'wrap'
        }}
      >
        Withdraw
      </Flex>
      <Input
        type="text"
        id="withdraw-recipient"
        placeholder="Recipient"
        title="Gnosis address, Bermuda Bay, Circles UBI, or ENS name"
        onChange={e => dispatch(dump({ withdrawRecipient: e.target.value }))}
        value={withdrawRecipient ?? ''}
        bg="#fff"
        sx={{ marginBottom: '0.625em', fontSize: [16, 18, 20] }}
      />
      <ShieldedTokenInput />
      {selectedToken === 'WXDAI' ? (
        <Box>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Label
              sx={{
                display: 'inline-grid',
                pointerEvents: 'none',
                marginBottom: '0.3125em',
                fontSize: [16, 18, 20]
              }}
            >
              <Checkbox
                id="withdrawUnwrap"
                checked={withdrawUnwrap === undefined ? true : withdrawUnwrap}
                sx={{
                  color: '#000',
                  pointerEvents: 'auto',
                  cursor: 'pointer'
                }}
                onChange={e => {
                  const state = { withdrawUnwrap: e.target.checked }
                  if (!e.target.checked) {
                    state.modalTitle = '⚠️ Warning'
                    state.modalText =
                      'Make sure the withdrawal address has XDAI for gas.'
                  }
                  dispatch(dump(state))
                }}
              />
              <Text sx={{ display: 'inline-block' }}>Unwrap WXDAI</Text>
            </Label>
          </Flex>
        </Box>
      ) : null}

      {/* {selectedToken === 'WXDAI' ? ( */}
      <Box>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Label
            sx={{
              display: 'inline-grid',
              pointerEvents: 'none',
              margin: '0 0 0.3125em 0',
              fontSize: [16, 18, 20],
              width: 'auto'
            }}
          >
            <Checkbox
              id="withdrawUseGelatoRelay"
              checked={withdrawUseGelatoRelay}
              sx={{
                color: '#000',
                pointerEvents: 'auto',
                cursor: 'pointer',
                marginTop: [0, 0, '0.125em']
              }}
              onChange={e => {
                const state = { withdrawUseGelatoRelay: e.target.checked }
                if (!e.target.checked) {
                  state.modalTitle = '⚠️ Warning'
                  state.modalText =
                    'Use separate Ethereum accounts to fund and withdraw.'
                }
                dispatch(dump(state))
              }}
            />
            {
              /* {selectedToken === 'GNO' ? null :   */
              'Use Gelato Relay'
            }

            {/* } */}
          </Label>
          {/* <Box sx={{ fontSize: [10, 12], alignSelf: 'center' }}>
              -
              {formatUnits(gelatoFee.toHexString(), token.decimals).slice(0, 6)}
              {selectedToken}
            </Box> */}
        </Flex>
        <Flex
          sx={{ marginBottom: '0.3125em', justifyContent: 'space-between' }}
        >
          {/* <span style={{ fontWeight: 'bold', marginRight: '0.3125em' }}>
              TOTAL
            </span> */}
          {/* <span>
              -{total}
              {selectedToken}
            </span> */}
        </Flex>
      </Box>
      {/* ) : null} */}

      <Button
        disabled={!(account && shieldedAddress && valid)}
        aria-label="Withdraw"
        style={{
          cursor: valid ? 'pointer' : 'not-allowed'
        }}
        onClick={() =>
          dispatch(withdraw(shieldedTokenAmount, withdrawRecipient))
        }
      >
        Withdraw
      </Button>
    </Box>
  )
}
