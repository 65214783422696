const { getAddress } = require('ethers')
const { GelatoRelay } = require('@gelatonetwork/relay-sdk')
const { mapTransactArgs, progress } = require('./utils')

module.exports = config => {
  const { prepareTransact } = require('./core')(config)
  const gelato = new GelatoRelay()

  async function relaySponsored({ pool, ...rest }) {
    const { args, extData } = await prepareTransact({ pool, ...rest })
    const poolie = await pool.getAddress().then(a => a.toLowerCase())
    console.log('gelato on address:', poolie)
    const relay = new GelatoRelay()
    const { taskId } = await relay.sponsoredCall(
      {
        chainId: Number(100),
        target: await pool.getAddress().then(a => a.toLowerCase()),
        data: pool.interface.encodeFunctionData(
          'transact',
          mapTransactArgs([args, extData])
        )
      },
      '9iYO20gMm4jz7JFzu186sNJpWkfMgagAlz_TPYQlmHc_'
    )
    console.log('taskId', taskId)
    return { taskId }
  }

  async function relay({
    pool,
    inputs,
    outputs,
    recipient,
    token,
    fee,
    unwrap
  }) {
    progress('Preparing Gelato Relay')
    token = getAddress(token)
    const { args, extData } = await prepareTransact({
      pool,
      inputs,
      outputs,
      fee: fee || 0,
      recipient,
      token,
      relayer: 0,
      unwrap
    })
    progress('Dispatching transaction via Gelato Relay')
    const res = await gelato.callWithSyncFee({
      chainId: Number(config.chainId),
      target: await pool.getAddress(),
      data: pool.interface.encodeFunctionData(
        'transactGelato',
        mapTransactArgs([args, extData])
      ),
      feeToken: token,
      isRelayContext: true
    })

    return res
  }

  async function relayFeeEstimate(feeToken, chainId = Number(config.chainId)) {
    return await gelato.getEstimatedFee(chainId, feeToken, 1100000, false)
  }

  return { relay, relaySponsored, relayFeeEstimate }
}
