const { bigNumToHex } = require('./utils')

module.exports = { prove2, prove16 }

async function loadDeps() {
  const { groth16 } = await import('snarkjs')
  const { utils: ffutils } = await import('ffjavascript')
  return { groth16, ffutils }
}

async function prove2(input, baseUrl) {
  const { groth16, ffutils } = await loadDeps()
  const { proof } = await groth16.fullProve(
    ffutils.stringifyBigInts(input),
    `${baseUrl}/transaction2.wasm`,
    `${baseUrl}/transaction2.zkey`
  )
  return (
    '0x' +
    bigNumToHex(proof.pi_a[0]).slice(2) +
    bigNumToHex(proof.pi_a[1]).slice(2) +
    bigNumToHex(proof.pi_b[0][1]).slice(2) +
    bigNumToHex(proof.pi_b[0][0]).slice(2) +
    bigNumToHex(proof.pi_b[1][1]).slice(2) +
    bigNumToHex(proof.pi_b[1][0]).slice(2) +
    bigNumToHex(proof.pi_c[0]).slice(2) +
    bigNumToHex(proof.pi_c[1]).slice(2)
  )
}

async function prove16(input, baseUrl) {
  const { groth16, ffutils } = await loadDeps()
  const { proof } = await groth16.fullProve(
    ffutils.stringifyBigInts(input),
    `${baseUrl}/transaction16.wasm`,
    `${baseUrl}/transaction16.zkey`
  )
  return (
    '0x' +
    bigNumToHex(proof.pi_a[0]).slice(2) +
    bigNumToHex(proof.pi_a[1]).slice(2) +
    bigNumToHex(proof.pi_b[0][1]).slice(2) +
    bigNumToHex(proof.pi_b[0][0]).slice(2) +
    bigNumToHex(proof.pi_b[1][1]).slice(2) +
    bigNumToHex(proof.pi_b[1][0]).slice(2) +
    bigNumToHex(proof.pi_c[0]).slice(2) +
    bigNumToHex(proof.pi_c[1]).slice(2)
  )
}
