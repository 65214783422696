const { randomBytes } = require('crypto')
const { BLAKE2s } = require('@stablelib/blake2s')
const { PUBLIC_KEY_LENGTH, sharedKey: x25519 } = require('@stablelib/x25519')
const {
  NONCE_LENGTH,
  KEY_LENGTH,
  XChaCha20Poly1305
} = require('@stablelib/xchacha20poly1305')

const ZERO_PUBLIC_KEY = Buffer.alloc(PUBLIC_KEY_LENGTH)

module.exports = { seal, open }

/**
 * Encrypts and authenticates a message with x25519-xchacha20-poly1305.
 *
 * @param {x25519.KeyPair} senderKeyPair Curve25519 sender key pair
 * @param {Uint8Array} recipientPublicKey The public key of the message recipient.
 * @param {Uint8Array} msg The plaintext message data.
 * @param {boolean} isEphemeral Whether the sender keyppair is ephemeral
 * @returns {Buffer} of nonce:24,ciphertext,tag:16
 */
function seal(senderKeyPair, recipientPublicKey, msg, isEphemeral) {
  // get a nonce from a csprng
  const nonce = randomBytes(NONCE_LENGTH)
  const aad = Buffer.concat([
    nonce,
    isEphemeral ? senderKeyPair.publicKey : ZERO_PUBLIC_KEY
  ])

  // doing x25519 - that is do diffie-hellman ontop of curve25519
  const rawSharedKey = x25519(
    senderKeyPair.secretKey,
    recipientPublicKey,
    true /*reject zero output*/
  )
  // rehashing the shared secret for use as key material
  const sharedKey = new BLAKE2s(KEY_LENGTH).update(rawSharedKey).digest()

  // encrypt and authenticate
  const xchacha20poly1305 = new XChaCha20Poly1305(sharedKey)
  const ciphertextPlusTag = xchacha20poly1305.seal(nonce, msg, aad)

  xchacha20poly1305.clean()
  rawSharedKey.fill(0x00)
  sharedKey.fill(0x00)

  // nonce:24,ephpubkey:32,ciphertext,tag:16
  return Buffer.concat([aad, ciphertextPlusTag])
}

/**
 * Decrypts and authenticates a message with x25519-xchacha20-poly1305.
 *
 * @param {x25519.KeyPair} keyPair Curve25519 key pair
 * @param {x25519.KeyPair.publicKey[]} peers Peer public keys
 * @param {Uint8Array} msg The raw enrypted msg incl aad, ciphertext, and tag.
 * @returns {null | [ Buffer, Buffer]} [plaintext,peer.x25519.publicKey]
 */
function open(keyPair, peers, msg) {
  const nonce = msg.subarray(0, NONCE_LENGTH)
  const ephemeralPublicKey = Buffer.from(
    msg.subarray(NONCE_LENGTH, NONCE_LENGTH + PUBLIC_KEY_LENGTH)
  )
  const aad = Buffer.concat([nonce, ephemeralPublicKey])
  const ciphertextPlusTag = msg.subarray(
    NONCE_LENGTH + PUBLIC_KEY_LENGTH,
    msg.length
  )
  let plaintext

  if (!ephemeralPublicKey.equals(ZERO_PUBLIC_KEY)) {
    // peers = [ephemeralPublicKey]
    // doing x25519 - that is do diffie-hellman ontop of curve25519
    const rawSharedKey = x25519(
      keyPair.secretKey,
      ephemeralPublicKey,
      true /*reject zero output*/
    )

    // rehashing the shared secret for use as key material
    const sharedKey = new BLAKE2s(KEY_LENGTH).update(rawSharedKey).digest()

    // authenticate and decrypt
    const xchacha20poly1305 = new XChaCha20Poly1305(sharedKey)
    plaintext = xchacha20poly1305.open(nonce, ciphertextPlusTag, aad)

    xchacha20poly1305.clean()
    rawSharedKey.fill(0x00)
    sharedKey.fill(0x00)

    if (plaintext) {
      return [Buffer.from(plaintext), ephemeralPublicKey]
    }
  }

  // find the x25519 counterparty
  for (const publicKey of peers) {
    // doing x25519 - that is do diffie-hellman ontop of curve25519
    const rawSharedKey = x25519(
      keyPair.secretKey,
      publicKey,
      true /*reject zero output*/
    )
    // rehashing the shared secret for use as key material
    const sharedKey = new BLAKE2s(KEY_LENGTH).update(rawSharedKey).digest()

    // authenticate and decrypt
    const xchacha20poly1305 = new XChaCha20Poly1305(sharedKey)
    plaintext = xchacha20poly1305.open(nonce, ciphertextPlusTag, aad)

    xchacha20poly1305.clean()
    rawSharedKey.fill(0x00)
    sharedKey.fill(0x00)

    if (plaintext) {
      return [Buffer.from(plaintext), publicKey]
    }
  }

  return null
}
