import React from 'react'
import { Box } from 'rebass'
import { useSelector } from 'react-redux'
import Fund from './fund'
import Transfer from './transfer'
import Withdraw from './withdraw'
import Registry from './registry'
import History from './history'

export default function Home() {
  const { selectedMenu } = useSelector(state => state)
  let selectedComponent
  if (selectedMenu === 'fund') {
    selectedComponent = <Fund />
  } else if (selectedMenu === 'transfer') {
    selectedComponent = <Transfer />
  } else if (selectedMenu === 'withdraw') {
    selectedComponent = <Withdraw />
  } else if (selectedMenu === 'history') {
    selectedComponent = <History />
  }
  // else if (selectedMenu === 'registry') {
  //   selectedComponent = <Registry />
  // }
  return (
    <Box
      sx={{
        margin: '3vh auto auto auto',
        background: '#fff',
        padding: 0,
        maxWidth: selectedMenu === 'history' ? '100%' : '24em',
        width: selectedMenu === 'history' ? '100%' : 'auto'
      }}
    >
      {selectedComponent}
    </Box>
  )
}
