const { BigNumber } = require('@ethersproject/bignumber')
const { Wallet } = require('ethers')
const { poseidonHash, bigNumToHex } = require('./utils')
const x25519xchacha20poly1305 = require('./x25519-xchacha20-poly1305')
const x25519 = require('@stablelib/x25519')

module.exports = class KeyPair {
  /**
   * Initialize a new keypair. Generates a random private key if not defined
   *
   * @param {string} privkey
   */
  constructor(privkey = Wallet.createRandom().privateKey) {
    this.privkey = privkey
    this.pubkey = poseidonHash([this.privkey])
    this.x25519 = x25519.generateKeyPairFromSeed(
      Buffer.from(privkey.slice(2), 'hex')
    )
  }

  toString() {
    return (
      bigNumToHex(this.pubkey) +
      Buffer.from(this.x25519.publicKey).toString('hex')
    )
  }

  /**
   * Key address for this keypair, alias to {@link toString}
   *
   * @returns {string}
   */
  address() {
    return this.toString()
  }

  /**
   * Initialize new keypair from address string
   *
   * @param str
   * @returns {KeyPair}
   */
  static fromString(str) {
    if (str.length === 130) {
      str = str.slice(2)
    }
    if (str.length !== 128) {
      throw new Error('Invalid key length')
    }
    return Object.assign(new KeyPair(), {
      privkey: null,
      pubkey: BigNumber.from('0x' + str.slice(0, 64)),
      x25519: {
        publicKey: Buffer.from(str.slice(64, 128), 'hex'),
        secretKey: null
      }
    })
  }

  /**
   * Sign a message using keypair private key
   *
   * @param {string|number|BigNumber} commitment a hex string with commitment
   * @param {string|number|BigNumber} merklePath a hex string with merkle path
   * @returns {BigNumber} a hex string with signature
   */
  sign(commitment, merklePath) {
    return poseidonHash([this.privkey, commitment, merklePath])
  }

  /**
   * Encrypt data using keypair encryption key
   *
   * @param {x25519.KeyPair} spendingKeyPair Spending curve25519 key pair
   * @param {Buffer} buf
   * @returns {Buffer} encrypted enveloped data
   */
  encrypt(spendingKeyPair, buf) {
    const isEphemeral = !spendingKeyPair
    spendingKeyPair = spendingKeyPair || new KeyPair()
    return x25519xchacha20poly1305.seal(
      spendingKeyPair.x25519,
      this.x25519.publicKey,
      buf,
      isEphemeral
    )
  }

  /**
   * Decrypt data using keypair private key
   * @param {x25519.KeyPair.publicKey[]} peers Peer public keys
   * @param {Buffer} buf an encrypted and authenticated envelope
   * @returns {null | [Buffer, Buffer]} [plaintext, x25519.KeyPair.publicKey]
   */
  decrypt(peers, buf) {
    return x25519xchacha20poly1305.open(this.x25519, peers, buf)
  }
}
