import { default as ReactModal } from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Flex, Box, Image } from 'rebass'
import { formatEther } from 'ethers'
import { dump, useMyMetaMask } from './redux'
import { TOKENS } from './constants'

export default function WalletModal() {
  const dispatch = useDispatch()
  const { chainName } = useMyMetaMask()
  const { showWalletModal, shieldedBalance } = useSelector(state => state)

  return (
    <ReactModal
      isOpen={showWalletModal}
      onRequestClose={() => dispatch(dump({ showWalletModal: false }))}
      contentLabel="token modal"
      appElement={document.getElementById('root')}
      className="responsive-modal"
    >
      <Flex
        style={{
          justifyContent: 'center',
          marginTop: window.innerHeight < 500 ? '0' : '20vh'
        }}
      >
        <Box
          sx={{
            background: '#fff',
            border: '0.1875em solid #000',
            boxShadow: '0 0.625em',
            padding: '0.625em',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: ['1em', '1.25em'],
            width: ['100vw', '75vw', '50vw']
          }}
        >
          <Text sx={{ fontWeight: 'bold', marginBottom: '0.625em' }}>
            💰 Wallet
          </Text>
          <Text sx={{ marginBottom: '1.25em' }}>
            Overview your shielded funds.
          </Text>
          <Box>
            {TOKENS.map(({ symbol, image, address }) => {
              return address[chainName] ? (
                <Box key={symbol}>
                  <hr style={{ marginBottom: '0.725em' }} />
                  <Image
                    src={image}
                    sx={{
                      width: ['1.4em'],
                      borderRadius: 10,
                      verticalAlign: 'top',
                      margin: '0 0.3125em 0.3125em 0'
                    }}
                  ></Image>
                  <Text
                    sx={{
                      minWidth: ['5em', '5.625em'],
                      display: 'inline-block'
                    }}
                  >
                    {formatEther(String(shieldedBalance[symbol] || 0))}
                    {symbol}
                  </Text>
                </Box>
              ) : null
            })}
          </Box>
        </Box>
      </Flex>
    </ReactModal>
  )
}
