const { keccak256 } = require('ethers')
// ethers@v6 fails resolving ens names
const { getDefaultProvider } = require('ethers5')
const { default: Safe, Web3Adapter } = require('@safe-global/protocol-kit')
const Web3 = require('web3')
const { NATIVE_ADDRESS_PATTERN, SHIELDED_ADDRESS_PATTERN } = require('./utils')

module.exports = config => {
  // we lookup ens names on mainnet
  const ethereum = getDefaultProvider('https://rpc.eth.gateway.fm')
  return {
    /// Downloads the entire registry to config.peers.
    async load() {
      const registered = await this.list()
      config.peers = config.peers || []
      config.peers = config.peers.concat(registered.map(r => r.shieldedAddress))
    },
    /**
     * Lists all registry entries.
     * @returns [ { nativeAddress, shieldedAddress, name, expiry }, ... ]
     */
    async list() {
      return config.registry
        .queryFilter(config.registry.filters.Registered(), 0)
        .then(events =>
          events.map(({ args }) => ({
            nativeAddress: args.nativeAddress,
            shieldedAddress: args.shieldedAddress,
            name: args.name,
            expiry: args.expiry
          }))
        )
    },
    /**
     * Searches for a particular shielded address entry.
     * @param shieldedAddress
     * @return { nativeAddress, shieldedAddress, name, expiry } Record
     */
    async find(shieldedAddress) {
      return this.list().then(l =>
        l.find(entry => entry.shieldedAddress === shieldedAddress)
      )
    },
    /**
     * Whether given shielded address is registered.
     * @param shieldedAddress Shielded addresss
     * @return Registered bool flag
     */
    async isRegistered(shieldedAddress) {
      const entry = await this.find(shieldedAddress)
      return !!entry
    },
    /**
     * Get the registration fee for a name.
     * @param name .bay name
     * @return Registration fee for given name
     */
    async getFee(name) {
      return config.registry.getFee(Buffer.from(name, 'utf8'))
    },
    /**
     * Get the registration term.
     * @return Registration term
     */
    async getTerm() {
      return config.registry.term().then(Number)
    },
    /**
     * Looks up a shielded by a native address.
     * @param address Native address
     * @return Shielded address string
     */
    async shieldedAddressOf(address) {
      return config.registry.shieldedAddressOf(address)
    },
    /**
     * Looks up a shielded address by a Bermuda Bay name.
     * @param name .bay name
     * @return Shielded address string
     */
    async shieldedAddressOfName(name) {
      return config.registry.shieldedAddressOfNameHash(
        keccak256(Buffer.from(name.toLowerCase(), 'utf8'))
      )
    },
    /**
     * Looks up a native address by a shielded address.
     * @param shieldedAddress Shielded address
     * @returns Native address
     */
    async nativeAddressOf(shieldedAddress) {
      return this.find(shieldedAddress).then(
        ({ nativeAddress }) => nativeAddress
      )
    },
    /**
     * Looks up a native address by a Bermuda Bay name.
     * @param name .bay name
     * @returns Native address
     */
    async nativeAddressofName(name) {
      const shieldedAddress = await this.shieldedAddressOfName(name)
      return this.nativeAddressOf(shieldedAddress)
    },
    /**
     * Looks up a name by a shielded address.
     * @param shieldedAddress Shielded address
     * @returns .bay name
     */
    async nameOfShieldedAddress(shieldedAddress) {
      return config.registry
        .nameOfShieldedAddress(shieldedAddress)
        .then(hexString =>
          Buffer.from(hexString.replace('0x', ''), 'hex').toString('utf8')
        )
    },
    /**
     * Looks up a name by a native address.
     * @param nativeAddress Gnosis address
     * @returns .bay name
     */
    async nameOfNativeAddress(nativeAddress) {
      return config.registry
        .nameOfNativeAddress(nativeAddress)
        .then(hexString =>
          Buffer.from(hexString.replace('0x', ''), 'hex').toString('utf8')
        )
    },
    /**
     * Looks up the expiry of a name.
     * @param name .bay name
     * @return Expiry timestamp (in seconds)
     */
    async expiryOf(name) {
      return config.registry.expiryOf(name).then(Number)
    },
    /**
     * Registers a shielded account including a name.
     * @param signer Ethers signer
     * @param shieldedAddress Shielded address
     * @param name .bay name
     * @param safeAddress Safe address (optional)
     */
    async register(signer, shieldedAddress, name = '', safeAddress) {
      if (safeAddress) {
        const web3 = new Web3(window.ethereum)
        const ethAdapter = new Web3Adapter({
          web3,
          signerAddress: signer.address
        })
        const safe = await Safe.create({
          ethAdapter,
          safeAddress
        })
        const registryAddress = await config.registry.getAddress()
        const rawData = config.registry.interface.encodeFunctionData(
          'register',
          [
            Buffer.from(shieldedAddress.replace('0x', ''), 'hex'),
            Buffer.from(name, 'utf8')
          ]
        )
        const safeTransactionData = {
          to: registryAddress,
          data: rawData,
          value: '0'
        }
        const safeTx = await safe.createTransaction({ safeTransactionData })
        const signedSafeTx = await safe.signTransaction(
          safeTx,
          'eth_signTypedData_v4'
        )
        const result = await safe.executeTransaction(signedSafeTx)
        return result
      } else {
        return config.registry
          .connect(signer)
          .register(
            Buffer.from(shieldedAddress.replace('0x', ''), 'hex'),
            Buffer.from(name, 'utf8'),
            { gasLimit: 250000 }
          )
          .then(response => {
            return config.provider.waitForTransaction(response.hash)
          })
          .then(receipt => {
            if (receipt.status !== 1) {
              throw Error('registration failed')
            }
            return receipt
          })
      }
    },
    /**
     * Renews an effective registration for another term, i.e. the
     * term following the current registration's expiry. Does only allow
     * renewals one term in advance.
     * @param signer Ethers signer
     * @param name .bay name
     */
    async renew(signer, name) {
      return config.registry
        .connect(signer)
        .renew(keccak256(name.toLowerCase()))
    },
    /**
     * Resolves given input to a shielded address.
     *
     * @param {*} x Arbitrary user input
     * @returns Shielded address or undefined
     */
    async resolveShieldedAddress(x) {
      if (SHIELDED_ADDRESS_PATTERN.test(x)) {
        return x
      } else if (x.endsWith('.bay')) {
        return this.shieldedAddressOfName(x).then(r =>
          r.length === 130 ? r : undefined
        )
      } else if (NATIVE_ADDRESS_PATTERN.test(x)) {
        return this.shieldedAddressOf(x).then(r =>
          r.length === 130 ? r : undefined
        )
      } else if (x.endsWith('.eth')) {
        const nativeAddress = await ethereum.resolveName(x)
        return this.shieldedAddressOf(nativeAddress).then(r =>
          r.length === 130 ? r : undefined
        )
      } else {
        // assume it is a cirlces ubi name
        const safeAddress = await fetch(
          `https://api.circles.garden/api/users/${x}`
        )
          .then(r => (r.status !== 200 ? undefined : r.json()))
          .then(usr => usr?.data?.safeAddress)
        if (!safeAddress) {
          return undefined
        } else {
          return this.shieldedAddressOf(safeAddress).then(r =>
            r.length === 130 ? r : undefined
          )
        }
      }
    },
    /**
     * Resolves given input to a native address.
     *
     * @param {*} x Arbitrary user input
     * @returns Native address or undefined
     */
    async resolveNativeAddress(x) {
      if (NATIVE_ADDRESS_PATTERN.test(x)) {
        return x
      } else if (x.endsWith('.eth')) {
        return ethereum.resolveName(x)
      } else if (SHIELDED_ADDRESS_PATTERN.test(x)) {
        return this.nativeAddressOf(x).then(r =>
          r.length === 42 ? r : undefined
        )
      } else if (x.endsWith('.bay')) {
        return this.nativeAddressOfName(x)
      } else {
        // assume it is a cirlces ubi name
        return fetch(`https://api.circles.garden/api/users/${x}`)
          .then(r => (r.status !== 200 ? undefined : r.json()))
          .then(usr => usr?.data?.safeAddress)
      }
    },
    /**
     * Looks up an ENS name given a native address.
     *
     * @param {string} nativeAddress Native address
     * @returns {string} ENS name or null
     */
    async lookupEnsName(nativeAddress) {
      return ethereum.lookupAddress(nativeAddress)
    }
  }
}
