import { default as ReactModal } from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Flex, Box } from 'rebass'
import { Input } from '@rebass/forms'
import { keccak256, toUtf8Bytes } from 'ethers'
import Button from './button'
import { deriveShieldedAccount, dump } from './redux'
import { getSigner } from './util'

export default function ConnectModal() {
  const dispatch = useDispatch()
  const { showConnectModal, shieldedAccountSeed, showSecretSeedInput } =
    useSelector(state => state)
  function initShieldedAccount(seed) {
    const shieldedPrivateKey = keccak256(seed)
    dispatch(
      dump({
        shieldedPrivateKey /*FIXME*/,
        selectedMenu: 'fund',
        modalTitle: null,
        modalText: null,
        showConnectModal: false,
        dots: true,
        progress: 'Initializing'
      })
    )
    dispatch(deriveShieldedAccount(shieldedPrivateKey))
  }

  return (
    <ReactModal
      isOpen={showConnectModal}
      onRequestClose={() => dispatch(dump({ showConnectModal: false }))}
      contentLabel="connect modal"
      appElement={document.getElementById('root')}
      className="responsive-modal"
    >
      <Flex
        style={{
          justifyContent: 'center',
          marginTop: window.innerHeight < 500 ? '0' : '20vh'
        }}
      >
        <Box
          sx={{
            background: '#fff',
            border: '0.1875em solid #000',
            boxShadow: '0 0.625em',
            padding: '0.625em',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: ['1em', '1.25em']
          }}
        >
          <Text sx={{ fontWeight: 'bold', marginBottom: '0.625em' }}>
            🔌 Connect
          </Text>
          <Box sx={{ marginBottom: '0.625em' }}>
            <Text>Provide a shielded account secret seed.</Text>
            <Flex style={{ flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
              <Button
                aria-label="Default"
                style={{
                  margin: '1.25em 0 0 0',
                  width: 'auto'
                }}
                onClick={async () => {
                  const signer = await getSigner()
                  // WARNING: this signedMsg must be treated confidentially!
                  const signedMsg = await signer.signMessage(
                    'BERMUDA_BAY_SHIELDED_ACCOUNT_V0'
                  )
                  initShieldedAccount(signedMsg)
                }}
              >
                Default
              </Button>
              <Button
                aria-label="Default"
                style={{
                  margin: '1.25em 0 0 0',
                  width: 'auto'
                }}
                onClick={() => {
                  if (shieldedAccountSeed) {
                    initShieldedAccount(toUtf8Bytes(shieldedAccountSeed))
                    dispatch(dump({ shieldedAccountSeed: null }))
                  } else {
                    dispatch(dump({ showSecretSeedInput: true }))
                  }
                }}
              >
                {shieldedAccountSeed ? 'Connect' : 'Custom'}
              </Button>
            </Flex>
          </Box>
          {showSecretSeedInput ? (
            <Flex>
              <Input
                type="password"
                id="shielded-account-secret-seed"
                placeholder="Secret seed"
                onChange={e =>
                  dispatch(dump({ shieldedAccountSeed: e.target.value }))
                }
                value={shieldedAccountSeed || ''}
                bg="#fff"
                sx={{ margin: '0.625em 0 0.625em 0', fontSize: [16, 18, 20] }}
              />
            </Flex>
          ) : null}
        </Box>
      </Flex>
    </ReactModal>
  )
}
